
/******************
  Сервисы
******************/
section.services {
  padding-bottom: 40px;
}
section.services, section.works {
  h2 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 4px;
    color: @textheader;
  }
  .services-box {
    text-align: center;
    margin-bottom: 10px;
    padding: 15px 10px;
    border: 1px solid #eeeeee;
    i, em {
      color: @textheader;
      font-size: 24px;
      margin-bottom: 15px;
      width: 70px;
      height: 70px;
      line-height: 63px;
      text-align: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      border: 4px solid @textheader;
      -webkit-transition: all 200ms ease-in;
      -o-transition: all 200ms ease-in;
      -moz-transition: all 200ms ease-in;
      transition: all 200ms ease-in;
    }
    &:hover i, &:hover em {
      background: @textheader;
      color: @white;
    }
    h4 {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 4px;
      color: @textheader;
      &:first-letter {
        color: inherit;
        background: inherit;
        padding: 0;
      }
    }
  }
}
@media (min-width: 768px) {
  section.services .col-sm-6 {
    height: 242px;
  }
}
/**** Сервисы конец ****/