

/*********************************
      Статья
*********************************/
section.main {
  padding-bottom: 70px;
}
main {
  //  padding-top: 20px;
  padding-bottom: 20px;
}
.item-page {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
    color: @textheader;
    background: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px;
    &.plash {
      line-height: 1.8em;
      color: @white;
      background: @textheader;
      padding: 0 10px;
      margin-top: 0;
    }
    a {
      color: @textheader;
    }
  }
  .actions {
    position: absolute;
    height: 20px;
    width: 20px;
    display: none;
    right: 0;
    top: 0;
  }
  &:hover .actions {
    display: block;
  }
}
.item-page {
  position: relative;
  h3, h4, h5, h6 {
    padding-left: 0;
    color: #000000;
    background: none;
  }
  ul.actions {
    margin-left: 0;
    li {
      list-style: none;
      display: inline;
    }
  }
  .article-info {
    color: #aaa;
    font-size: 12px;
    position: absolute;
    top: 35px;
    right: 0;
    dd {
      display: inline;
    }
    .article-info-term {
      display: none;
    }

  }
}
div[itemprop="articleBody"] {
  padding-top: 20px;
}
/*** end statya ***/
