
/************************
    Наши клиенты
*************************/
.partners {
  padding-top: 30px;
  h3 {
    font-size: 28px;
  }
}
div#j_content_wrapper {
  div.ca-wrapper {
    height: 90px;
    width: auto;
  }
  .ca-item {
    position: relative;
    float: left;
    width: 228px;
    height: 90px;
    text-align: center;
  }
  h3 {
    display: none;
  }
  div.ca-item-main {
    padding: 0px;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    overflow: hidden;
    -moz-box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
  }
}
/**** конец ****/