
/****ФУТЕР********/

footer.footdown {
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
  background: @textheader;
  color: @white;
  h1, h2, h3, h4, h5, h6 {
    color: @white;
    background: transparent;
    padding: 0px;
    margin: 0;
    //    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;

    &:first-letter {
      background-color: @white;
      color: @textheader;
      margin-right: 2px;
      padding: 2px 3px;
    }
  }
  //  div#foxcontainer_m142 .btn, div#foxcontainer_m142 button.btn:hover {
  //    #btn;
  //  }
  p {
    text-align: justify;
    color: #ddd;
    font-size: 10px;
  }
  #foxcontainer_m142 {
    button.btn {
      #btn;
      border: 2px solid @white;
      text-transform: uppercase;
      color: @white;
      background: @textheader;
      &:hover {
        color: @textheader;
        background: @white;
        border: 2px solid @white;
      }
    }
    #mid_142-buttons {
      > div.controls {
        text-align: right;
      }
    }
    input, textarea {
      background: #003260;
      color: @white;
      border: 0;
      padding: 10px;
    }
  }
  h2 {
    margin: 10px 0;
  }
  .control-group {
    margin: 10px 0;
  }

  .kateg {
    ul li {
      height: 40px;
      border-bottom: 1px dotted #ddd;
      padding: 10px 0;
      list-style: none;
      position: relative;
      &:after {
        content: "\f054";
        font-family: FontAwesome;
        top: 10px;
        right: 10px;
        position: absolute;
      }
      a {
        color: #eee;
      }
    }
  }

  .foonews {
    td img {
      width: 100%;
    }
    p {
      padding-left: 10px;
      text-align: left;
    }

  }
}

/*****************/
/**********************
    Модад
*********************/
div#myModal,
div#myModal2,
div#myModal3,
div#myModal4 {
  .modal-content {
    padding: 20px;
    border-radius: 0;
    .control-group {
      margin-bottom: 10px;
      * {
        border-radius: 0;
      }
    }
    button {
      #btn;
      border: 2px solid @white;
      text-transform: uppercase;
      color: @white;
      background: @textheader;
      box-shadow: none;
      &:hover {
        color: @textheader !important;
        background: @white;
        border: 2px solid @textheader;
      }
    }
    div[id^="foxcontainer"] {

    }
    h2 {
      font-size: 1.4em;
      padding: 0;
    }
    #mid_282-dropdown2,
    #mid_282-sender0,
    #mid_282-textarea0,
    #mid_282-text5,
    #mid_154-dropdown2,
    #mid_154-sender0,
    #mid_154-textarea0,
    #mid_154-text5,
    #mid_277-dropdown2,
    #mid_277-sender0,
    #mid_277-textarea0,
    #mid_277-text5 {
      position: relative;
      margin-top: 41px;
      &:after {
        position: absolute;
        top: -33px;
        left: -20px;
        width: 598px;
        background-color: @textheader;
        content: "";
        font-weight: bold;
        color: @white;
        padding: 3px 20px;
      }
    }
    #mid_154-dropdown2,
    #mid_154-text5,
    #mid_277-dropdown2,
    #mid_277-text5
    {
      &:after {
        content: "ИНФОРМАЦИЯ О КОМПАНИИ";
      }
    }
    #mid_282-sender0,
    #mid_281-sender0,
    #mid_154-sender0,
    #mid_277-sender0 {
      &:after {
        content: "ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ";
      }
    }
    #mid_154-textarea0,
    #mid_277-textarea0 {
      &:after {
        content: "ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ";
      }
    }
    #mid_282-dropdown2,
    #mid_282-sender0,
    #mid_282-textarea0,
    #mid_282-text5,
    #mid_281-dropdown2,
    #mid_281-sender0,
    #mid_281-textarea0,
    #mid_281-text5,
    #mid_226-dropdown2,
    #mid_226-sender0,
    #mid_226-textarea0,
    #mid_226-text5 {
      position: relative;
      margin-top: 41px;
      &:after {
        position: absolute;
        top: -33px;
        left: -20px;
        width: 598px;
        background-color: @textheader;
        content: "";
        font-weight: bold;
        color: @white;
        padding: 3px 20px;
      }
    }
    #mid_281-dropdown2,
    #mid_281-text5,
    #mid_226-dropdown2,
    #mid_226-text5
    {
      &:after {
        content: "ИНФОРМАЦИЯ О КОМПАНИИ";
      }
    }
    #mid_282-sender0,
    #mid_281-sender0,
    #mid_226-sender0 {
      &:after {
        content: "ПЕРСОНАЛЬНАЯ ИНФОРМАЦИЯ";
      }
    }
    #mid_226-textarea0 {
      &:after {
        content: "ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ";
      }
    }
  }
}
/****  конец модал  *****/