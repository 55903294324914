body {
  font-family: "Arial", "Helvetica", sans-serif;
  font-family: 'Raleway', sans-serif;
  font-family: 'Open Sans', sans-serif;
 }
h1, h2, h3, h4, h5, h6  {
  line-height: 1.8em;
  color: @textheader;
  background: transparent;
  padding: 0;
  margin-top: 0;
  font-weight: bold;

  a {
    color: @textheader;
  }
}
h1, h2, h3 {
  font-family: 'Lora', serif;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  &:first-letter {
    background-color: @textheader;
    color: #fff;
    padding: 2px 3px;
    margin-right: 2px;
  }
}
.space-30 {
  height: 30px;
}
.space-70 {
  height: 70px;
}
.wid50  {
  width: 50%;
}
.test p:first-letter {
  background-color: #00407b;
  color: #fff;
  padding: 2px 3px;
  margin-right: 1px;
}
h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: .8em;
}
.clr {
  clear: both;
  padding-top: 20px;
}
#btn {
  color: @textheader;
  background: @white;
 border: 0;
 
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.5);
  &:hover {
  
    color: @white !important;
    background: @textheader;
//    text-shadow: 0px 0px 2px rgba(0,0,0,.5);
  }
}
.btn {
  #btn;
}
.btn {
  //.btn-default {
  //  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  //}
  //.btn-primary {
  //  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
  //}
  //// Success appears as green
  //.btn-success {
  //  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
  //}
  //// Info appears as blue-green
  //.btn-info {
  //  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
  //}
  //// Warning appears as orange
  //.btn-warning {
  //  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
  //}
  //// Danger and error appear as red
  //.btn-danger {
  //  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
  //}
}
button {
 
  padding: 5px 5px;
  border-style: solid;
  border-radius: 8px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.4);
   background: -moz-linear-gradient(#FFF924,  #FFF324);
    background: -webkit-gradient(linear, 0 0, 0 100%, from(#FFF924), to(#FFF324));
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF924', endColorstr='#FFF324');
  white-space: nowrap;
  a {
    color: inherit;
  }
}
.page-header {
  padding-bottom: 0;
  margin: 40px 0 0px;
  border-bottom: 0;
  border-radius:5px;
}
.custom {
  z-index: 1;
  position: relative;
}
/****************************
  Обратная связь
****************************/
#top .foxcontainer {
  .control-group {
    margin-bottom: 10px;
  }
  button.btn {
    #btn;
  }
}
#sh_button {
  background: @textheader;
}
#awcontent>.foxcontainer {
  h2 {
    margin-top: 40px;
  }
}
.alert-error {
  color: #ff6666;
}
#top.error {
  position: relative;
  padding-top: 153px;
  &:before {
    content: "Вы ввели некорректные данные, попробуйте пожалуйста еще раз.";
    position: absolute;
    color: #990000;
    top: 130px;
    right: 0;
    left: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
}
#top.awsuccess {
  position: relative;
  padding-top: 153px;
  &:before {
    content: "Спасибо за обращение! В ближайшее время мы свяжемся с Вами.";
    position: absolute;
    color: #089919;
    top: 130px;
    right: 0;
    left: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 10;
  }
}
/****** цепочка ******/
//.cepochka {
//  position: relative;
//  .breadcrumb {
//    display: block;
//    position: absolute;
//    left: 20px;
//    top: 15px;
//  }
//}
/************************************
RESPONSIVE
************************************/
