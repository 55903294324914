.carousel-control {
  span {
    position: absolute;
    top: 50%;
    font-size: 30px;
    margin-top: -15px;
  }
  &.right span {
    right: 20px;
  }
  &.left span {
    left: 20px;
  }
}