/********************
    Шапка
********************/
header {
  height: 124px;
//  padding-top: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background: @white;
  box-shadow: 0 2px 2px 0 #ccc;
  a {
    color: #222;
  }
}
section.cont {
  background: #f9f9f9;
  box-shadow: inset 0 -1px 1px -1px rgba(0,0,0,.3);
  height: 32px;
  .textwidget {
    padding-top: 4px;
  }
  .btn-default {
    color: @white !important;
    background-color: @textheader !important;
    border: 0;
    border-radius:3px ;
    padding: 0 10px;
    .fa {
      padding-right: 8px;
      border-right: 1px solid @textheaderLight;
      margin-right: 5px;
      line-height: 2;
    }
  }
  .dropdown-menu {
    margin: 0;
    padding: 10px;
    right: 0;
    border-radius:2px;
    z-index: 2000;
    width: 100%;
    h2 {
//      margin: 10px 0;
      display: none;
    }
    .control-group {
      margin: 10px 0;
    }
    div#foxcontainer_m149 .btn, div#foxcontainer_m149 button.btn:hover {
      #btn;
      border: 2px solid @white;
      text-transform: uppercase;
      color: @white;
      background: @textheader;
      box-shadow: none;
      border-radius: 8px;
    
      &:hover {
        color: @textheader !important;
        font-weight: bold;
        background: @white;
        border: 2px solid @textheader;
        border-radius: 8px;
        
      }
    }
  }
}
.site {
  padding-top: 124px;
}
.logo img {
  width: 300px;
}
@media (max-width: 992px){
  .logo img {
    width: 250px;
    height: auto;
  }
}