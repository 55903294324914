/********************************
      БЛОГ
********************************/
div.blog {
  padding-bottom: 30px;
  padding-top: 30px;
  >.page-header {
    margin-top: 0;
    h2 {
    }
  }
  .items-row {
    padding-top: 30px;
  }
  .items-row.row-0 {
//    padding-top: 0px;
  }
  .item {
    position: relative;
    padding-top: 1px;
    padding-bottom: 30px;
    box-shadow: 0 0 1px 1px rgba(0,0,0,.3);
    .intro {
      max-height: 90px;
      overflow: hidden;
      h4, h2, h1, h3 {
        padding: 10px;
      }
    }
    p {
      padding: 10px;
      img {
        max-width: 100%;
      }
    }
    .item-image {
      margin-top: -1px;
      img {
        width: 100%;
      }
    }
    h1, h2, h3, h4, h5, h6  {
      color: @grayDark;
      background: transparent;
      padding: 0 ;
      margin-top: 0;
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      height: 83px;
      overflow: hidden;

      &:first-letter{
        background-color: transparent;
        color: inherit;
        padding: 0;
        margin-right: 0;
      }

      a {
        color: @grayDark;

        &:first-letter{
          background-color: transparent;
          color: inherit;
          padding: 0;
          margin-right: 0;
        }
      }
    }
    .page-header {
      padding: 10px;
      margin: 20px 0 0px;
//      border-bottom: 1px solid #eee;
    }
    .readmore {
      position: absolute;
      bottom: 0;
      margin-bottom: -18px;
      background: @white;
//      box-shadow: 0 0 1px 1px rgba(0,0,0,.3);
      left: 50%;
      margin-left: -65px;
      z-index: 10;
      padding: 0;
      a {
        text-transform: uppercase;
        color: @textheader;
        width: 130px;
        text-align: center;
        &:hover {
//          color: @textheader !important;
        }
      }
    }

  }
  &.category-module {
    .item {
      margin-bottom: 40px;
      padding-bottom: 30px;

      .center {
        text-align: center;
      }

      .mod-articles-category-introtext {
        overflow: hidden;
        height: 0px;
        padding: 0 10px;

        -webkit-transition: all 200ms ease-in;
        -o-transition: all 200ms ease-in;
        -moz-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
      }
      &:hover .mod-articles-category-introtext {
        height: 80px;

        -webkit-transition: all 200ms ease-in;
        -o-transition: all 200ms ease-in;
        -moz-transition: all 200ms ease-in;
        transition: all 200ms ease-in;
      }
      > .center {
        //display: none;
      }
    }
    .page-header {
      padding: 10px;
      margin: 0;
      min-height: 90px;
    }
//    >div:nth-child(4n+1) {
//      clear: both;
//    }
  }
  p a.btn {
    background: @white;
    color: @textheader;
    box-shadow: 0 0 1px 1px rgba(0,0,0,.3);
    &:hover {
      color: @white !important;
      background: @textheader;
    }
  }
}
.demo div.blog.category-module .item > .center {
  display: block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .category-module>div:nth-child(2n+1) {
    clear: both;
  }
}
@media (min-width: 992px) {
  .category-module>div:nth-child(4n+1) {
    clear: both;
  }
}
/**** end blog ****/