section.navbar {
  font-family: Segoe UI, Tahoma, Arial, Verdana, sans-serif;
  margin-bottom: 0;
  padding: 15px 0;
  height: 62px;
  .navbar-nav {
    position: relative;
    background: #fff;
//    z-index: 1000;
  }
  &.navbar-default {
    background-color: @white;
    border: none;
  }
  .navbar-nav>.active>a,
  .navbar-nav>.active>a:hover,
  .navbar-nav>.active>a:focus {
    color: @textheader;
    background: transparent;
//    font-size: 14px;
//    text-transform: uppercase;
//    font-weight: bold;
  }
  .navbar-nav>li>a {
    color: @black;
    font-weight: bold;
    text-transform: uppercase;
    padding: 21px 13px;
//    padding-top: 21px;
//    padding-bottom: 21px;
    font-size: 15px;
    &:hover {
      color: @textheader;
    }
  }
  .navbar-nav li a {
    font-size: 12px;
  }
  .navbar-nav ul.nav-child {
    display: none;
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin-top: 0;
//    z-index: 0;
  }
  .navbar-nav ul.nav-child:hover {
    height: auto;
    width: auto;
//    z-index: 1000;
  }
  .navbar-nav ul.nav-child:hover .nav-child:hover {
    height: auto;
    width: auto;
//    z-index: 1000;
  }
  .navbar-nav > li > .nav-child > li,
  .navbar-nav > li {
    position: relative;
//    z-index: 1111;
  }
  ul.navbar-nav > li:hover > ul.nav-child > li {
    padding-left: 15px;
  }
  .navbar-nav > li {
    &.deeper:after {
      content: "\f0dd";
      right: 2px;
      top: 19px;
      background: none;
      position: absolute;
      font-family: FontAwesome;
    }
  }
  .navbar-nav > li > .nav-child > li {
    &.deeper:after {
      content: "\f0d9";
      left: 5px;
      top: 7px;
      background: none;
      position: absolute;
      font-family: FontAwesome;
    }
  }
  .navbar-nav > li > .nav-child {
    position: absolute;
    top: 55px;
    margin-top: -70px;
    left: 0;
//    z-index: 0;
  }
  .navbar-nav > li > .nav-child > li > .nav-child {
    position: absolute;
    top: 0;
//    right: -220px;
    left: -166px;
    margin-top: -20px;
//    z-index: 0;
  }
  .navbar-nav > li:hover > .nav-child {
    display: block;
    height: auto;
    width: 167px;
    top: 55px;
    padding: 10px 20px;
//    padding: 0;
//    margin-top: 0;
    margin: 0;
    background: @white;
    box-shadow: 0 0 1px 1px #ccc;
    opacity: 1;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
//    z-index: 1000;
  }
  .navbar-nav > li:hover > .nav-child > li:hover > .nav-child {
     display: block;
     height: auto;
     width: 167px;
    padding: 10px;
     margin: 0;
     background: @white;
     box-shadow: 0 0 1px 1px #ccc;
     opacity: 1;
     -webkit-transition: all 200ms ease-in;
     -o-transition: all 200ms ease-in;
     -moz-transition: all 200ms ease-in;
     transition: all 200ms ease-in;
   }
  .navbar-nav > li > .nav-child > li,
  .navbar-nav > li > .nav-child > li > .nav-child > li
  {
    height: 0;
    &:hover {
      background: @textheader;
      >a {
        color: @white;
        text-decoration: none;
      }
    }

  }
  .nav-child li {
    background: @white;
  }
  .navbar-nav > li:hover > .nav-child > li {
    height: auto;
    padding: 5px 10px;
    list-style: none;
    border-bottom: 1px dotted rgba(0,0,0,.3);
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
  .navbar-nav > li:hover > .nav-child > li:hover > .nav-child > li {
    height: auto;
    padding: 5px 10px;
    list-style: none;
    border-bottom: 1px dotted rgba(0,0,0,.3);
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
}
/*** z-index ***/
section.navbar {
  .navbar-nav {
    ul.nav-child {
      z-index: 1;
      li {
        //z-index: 0;
      }
    }
    > ul.nav-child {
      z-index: 1000;
      > li {
        z-index: 1010;
        > ul {
          z-index: 11000;
          > li {
            z-index: 11100;
            > ul {
              z-index: 111100;
              > li {
                z-index: 111200;
              }
            }
          }
        }
      }
    }
  }
}
/*** колонки ***/
section.navbar ul.navbar-nav  li.item-455:hover > ul.nav-child,
section.navbar ul.navbar-nav  li.item-542:hover > ul.nav-child
{

  display: flex;
  flex-wrap: wrap;

//  max-height: 300px;
//  height: 327px;
  width: 468px;
  > li {
    position: relative;
  //  position: absolute;
  //  left: 10px;
  }
  //> li:nth-child(1), li:nth-child(12), li:nth-child(23) {
  //  top: 10px;
  //}
  //> li:nth-child(2), li:nth-child(13), li:nth-child(24) {
  //  top: 38px;
  //}
  //> li:nth-child(3), li:nth-child(14), li:nth-child(25) {
  //  top: 66px;
  //}
  //> li:nth-child(4), li:nth-child(15), li:nth-child(26) {
  //  top: 94px;
  //}
  //> li:nth-child(5), li:nth-child(16), li:nth-child(27) {
  //  top: 122px;
  //}
  //> li:nth-child(6), li:nth-child(17), li:nth-child(28) {
  //  top: 150px;
  //}
  //> li:nth-child(7), li:nth-child(18), li:nth-child(29) {
  //  top: 178px;
  //}
  //> li:nth-child(8), li:nth-child(19), li:nth-child(30) {
  //  top: 206px;
  //}
  //> li:nth-child(9), li:nth-child(20), li:nth-child(31) {
  //  top: 234px;
  //}
  //> li:nth-child(10), li:nth-child(21), li:nth-child(32) {
  //  top: 262px;
  //}
  //> li:nth-child(11), li:nth-child(22), li:nth-child(33) {
  //  top: 290px;
  //}

//  > li:nth-child(12), li:nth-child(24) {
//    top: 318px;
//  }


  //>  li:nth-child(12),
  //li:nth-child(13),
  //li:nth-child(14),
  //li:nth-child(15),
  //li:nth-child(16),
  //li:nth-child(17),
  //li:nth-child(18),
  //li:nth-child(19),
  //li:nth-child(20),
  //li:nth-child(21),
  //li:nth-child(22)
  //{
  //  left: 160px;
  //}
  //> li:nth-child(23),
  //li:nth-child(24),
  //li:nth-child(25),
  //li:nth-child(26),
  //li:nth-child(27),
  //li:nth-child(28),
  //li:nth-child(29),
  //li:nth-child(30),
  //li:nth-child(31),
  //li:nth-child(32),
  //li:nth-child(33)
  //{
  //  left: 320px;
  //}
}
section.navbar ul.navbar-nav  li.item-455  ul.nav-child > li,
section.navbar ul.navbar-nav  li.item-542  ul.nav-child > li
{
//  display: inline-block;
  width: 135px;
  //float: left;
}
section.navbar ul.navbar-nav  li.item-542:hover > ul.nav-child {
  width: 365px;
  height: 355px;
  > li {
    width: 345px;
    padding-right: 5px;
  }
    > li:nth-child(11), li:nth-child(23) {
      top: 290px;
    }
    > li:nth-child(12), li:nth-child(24) {
      top: 318px;
    }
  > li:nth-child(11),
  li:nth-child(12),
  li:nth-child(13)

  {
    left: 10px;
  }
}

@media (max-width: 767px){
  section.navbar .navbar-nav > li {
    > ul {
      display: none !important;
    }
    &.deeper:after {
      content: "";
      right: 2px;
      top: 19px;
      background: none;
      position: absolute;
      font-family: FontAwesome;
    }
  }
}
@media (max-width: 991px) and (min-width: 768px){
  section.navbar .navbar-nav {
    max-width: 400px;
    text-align: left;
    >li>a {
      //color: #000;
      //font-weight: bold;
      //text-transform: uppercase;
      padding: 3px 16px;
      //font-size: 15px;
    }
    > li {
      float: none;
      display: inline-block;
    }
    > li.deeper:after {
      //content: "\f0dd";
      //right: 2px;
      top: 1px;
      //background: none;
      //position: absolute;
      //font-family: FontAwesome;
    }
  }
}