form#login-form {
  width: 240px;
//  height: 185px;
  background: #322626;
  padding: 25px 20px 20px;
  margin: 0;
  .input-small {
    border-radius: 0;
    padding: 8px;
    width: 202px;
  }
  .input-prepend .add-on:first-child, .input-prepend .btn:first-child {
//    border-radius: 0;
    padding: 8px;

  }

  .input-prepend {
    position: relative;
  }

  .add-on {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1100;
    border: 0;
    padding-top: 8px;
    color: #000000;
    background: transparent;
    height: 18px;

  }

  input {
    background-color: rgb(255, 255, 255);
  }

  .btn {
    width: 220px;
  }
}

.login-top {
  text-align: right;
//  border-right: 1px solid #c2b1b1;
//  padding-right: 10px;

  & > a {
    cursor: pointer;
    &:hover {
    text-decoration: none;
  }
  }
  h3 {
    background: #322626;
    color: #ffffff;
    margin: 0;
    padding: 10px 20px;
  }
}

.dropdown-menu {
  border-radius: 2px;
  box-shadow: none;
  padding: 0;
  left: auto;
  right: -2px;
}

.unstyled {
  height: 18px;
}


/********логин форма для Джумла 2.5***************/
form#login-form {

  ul {
    margin: 0;
    height: 15px;
   li {
      list-style: none;
    }
  }
  p#form-login-remember {
    label {
      float: left;
      padding-top: 1px;
      padding-left: 8px;
    }
    input {
      float: left;
    }
  }
  #form-login-password, #form-login-username {
    input {
      width: 228px;
    }
    position: relative;
    margin-bottom: 0;
    i {
      position: absolute;
      top: 5px;
      right: 0;
      font-size: 14px;
      width: 30px;
      height: 30px;
      font-weight: normal;
      line-height: 18px;
      text-align: center;
      color: #000000;
    }
  }
  #form-login-username {
    padding-bottom: 5px;
  }
  #form-login-password {
    padding-bottom: 5px;
  }
  fieldset.userdata{
  input.button {
    margin: 10px 0;
    padding: 5px 0;
    width: 241px;
    color: #fff;
    background-color: #c2b1b1;
    text-shadow: none;
    border: 0;
 }
}
  }
#modlgn-username, #modlgn-passwd {
  border-radius: 0;
}
/*** форма после входа ***/
.dropdown-menu {
  .logout-button {
    padding-top: 20px;
    .button, .button:hover {
      //#knopka;
      width: 241px;
    }
  }
}
form#login-form .logout-button input.button {
  background-color: #c2b1b1;
}