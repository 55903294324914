/************************
    заказ в слайдере
*************************/
section.banner-top {
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    clear: both;
  }
  position: relative;
  padding-bottom: 1px;
  .zakaz {
    position: absolute;
    padding-top: 45px;
    top: 0;
    color: @white;
    left: 1em;
    right: 0;
    font-size: 2em;
    z-index: 1;
    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      clear: both;
    }
    .zzkk {
      padding: 0 10%;
    }
    label {
      color: @textheader;
      font-weight: normal;
      a {
        text-decoration: underline;
      }
    }
    button.btn {
      #btn;
      text-transform: uppercase;
      background: @textheader !important;
      color: @white !important;
      font-size: 58%;
      border:1px solid @white;
      width: 100%;
       border-radius: 8px;
      margin-bottom: 20px;
      
      span {
        font-size: 170%;
      }
    }
    button.btn.error {
      background: #770000 !important;
      &:before {
        content: "ошибка";
        position: absolute;
        color: #ffff00;
        top: 7px;
        right: 12px;
      }
    }
    .dropdown-menu {
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
      left: -1px;
      right: auto;
      top: 29px;
    }
    #foxcontainer_m103 {
      background: @textheader;
      padding: 40px;
      button.btn {
        #btn;
        border: 2px solid @white;
        text-transform: uppercase;
        background: @textheader !important;
        color: @white !important;
        width: 90%;
      }
      #mid_91-buttons {
        > div.controls {
          text-align: right;
        }
      }
      input, textarea {
        background: @white;
        color: @grayDark;
        border: 0;
        padding: 10px;
      }
      h1, h2, h3, h4, h5, h6 {
        color: @white;
        background: transparent;
        padding: 0px;
        margin: 0;
        font-size: 26px;
        line-height: 1.2em;
        //    font-weight: bold;
        //        text-transform: uppercase;

        &:first-letter {
          background-color: transparent;
          color: @white;
          margin-right: 0;
          padding: 0;
        }
      }
    }
    h2 {
      margin: 10px 0;
    }
    .control-group {
      margin: 15px 0;
    }
    .controls {
      color: @textheader;
    }
    div#foxcontainer_m103 .btn, div#foxcontainer_m103 button.btn:hover {
      #btn;
      border: 2px solid @white;
    }
  }
}

@media (max-width: 992px){
  section.banner-top {
    >.custom {
      display: none;
    }
    .zakaz {
      position: static;
    }
  }

}
@media (max-width: 500px){
  section.banner-top .zakaz button.btn {
    font-size: 40%;
  }

}