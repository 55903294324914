
/***********************
    Почему мы
***********************/
.pochmy {
  background: @textheader;
  color: @white;
  padding-top: 30px;
  padding-bottom: 50px;
  h1, h2, h3, h4, h5, h6 {
    color: @white;
    background: transparent;
    padding: 0px;
    margin: 0;
    margin-bottom: .4em;
    font-weight: bold;


  }
  h3 {
    &:first-letter {
      background-color: @white;
      color: @textheader;
      //      padding: 0;
      //      margin-right: 0;
    }
  }
  .services-icon i {
    color: @textheader;
    width: 80px;
    line-height: 80px;
    height: 80px;
    font-size: 34px;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 18px;
  }
}
/*** конец ***/

